import React from 'react';

import { useRouter } from 'next/router';

import cn from 'classnames';

import { InstallPwa } from '@/components/InstallPwa/InstallPwa';
import { useTranslate } from '@/hooks/use-translate';
import Button from '@/UI/Button';
import Container from '@/UI/Container';

import { en } from './en';
import { BuyIcon, GetToStorageIcon, SaleIcon, StorageIcon } from './icons';

import styles from './HeroHeader.module.scss';

export default function HeroHeader() {
	const router = useRouter();
	const { locale } = router;
	const t = useTranslate(en);

	return (
		<section className={cn(styles.section)}>
			<Container className={styles.container}>
				<h1 className={styles.title}>
					{t(
						'Международная цифровая платформа для хранения и оптовой торговли рыбой и морепродуктами',
					)}
				</h1>

				<p className={styles.subtitle}>
					{t(
						'Повышаем эффективность, помогаем масштабировать и устойчиво развивать бизнес участников оптовой торговли и логистики рыбной продукции через объединение на нашей удобной площадке',
					)}
				</p>

				<ul className={styles.list}>
					{(locale === 'en' ? enServices : services).map((service, index) => (
						<li key={index} className={styles.card}>
							<div className={styles.iconContainer}>
								<svg
									className={styles.iconBackground}
									width="7.6rem"
									height="7rem"
									viewBox="0 0 76 70"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M6.79926 51.7437C-7.87806 26.3219 6.91988 4.23814 27.8281 -7.83323C48.7363 -19.9046 72.9325 -13.7101 84.7415 6.74373C96.5506 27.1976 84.1743 55.4754 70.0704 63.6183C49.1622 75.6897 15.1811 66.2616 6.79926 51.7437Z"
										fill="#ECF3FD"
									/>
								</svg>
								{service.icon}
							</div>
							<h3 className={styles.serviceTitle}>{service.title}</h3>
							<p className={styles.serviceDescription}>{service.description}</p>
							<Button href={service.link} className={styles.link}>
								{service.buttonText}
							</Button>
						</li>
					))}
				</ul>
				<InstallPwa />
			</Container>
		</section>
	);
}

const services = [
	{
		title: (
			<span>
				Храните
				<br />
				<br />
			</span>
		),
		description: <span>Размещайте рыбопродукцию на складах по всей России</span>,
		link: '/search',
		icon: <StorageIcon />,
		buttonText: 'Найти склад',
	},
	{
		title: (
			<span>
				Покупайте
				<br />
				<br />
			</span>
		),
		description: (
			<span>Покупайте любую рыбопродукцию по интересующим вас ценам с любого склада</span>
		),
		link: '/buy-fish',
		icon: <BuyIcon />,
		buttonText: 'Купить рыбу',
	},
	{
		title: (
			<span>
				Торгуйте
				<br />
				<br />
			</span>
		),
		description: (
			<span>
				Легко, быстро и выгодно продавайте рыбопродукцию, получая входящие предложения о покупке
			</span>
		),
		link: '/personal-account/fish-products',
		icon: <SaleIcon />,
		buttonText: 'Продать рыбу',
	},
	{
		title: <span>Автоматизируйте склад</span>,
		description:
			'Привлекайте новых клиентов, улучшайте клиентский сервис автоматизацией приема заявок-распоряжений рыбопродукцией',
		link: '/storage',
		icon: <GetToStorageIcon />,
		buttonText: 'Добавить склад',
	},
];

const enServices = [
	{
		title: (
			<span>
				Store
				<br />
				<br />
			</span>
		),
		description: 'Store fish produce at warehouses across Russia',
		link: '/search',
		icon: <StorageIcon />,
		buttonText: 'Find a warehouse',
	},
	{
		title: (
			<span>
				Buy
				<br />
				<br />
			</span>
		),
		description: 'Purchase any fish produce at acceptable prices from any store',
		link: '/buy-fish',
		icon: <BuyIcon />,
		buttonText: 'Buy fish',
	},
	{
		title: (
			<span>
				Trade
				<br />
				<br />
			</span>
		),
		description:
			'Receive incoming purchase offerings and sell fish produce easily, quickly and profitably',
		link: '/personal-account/fish-products',
		icon: <SaleIcon />,
		buttonText: 'Sell fish',
	},
	{
		title: (
			<span>
				Automate storage
				<br />
				<br />
			</span>
		),
		description:
			'Attract new clients, improve customer service by automating processing of applications for fish produce disposal',
		link: '/storage',
		icon: <GetToStorageIcon />,
		buttonText: 'Add a warehouse',
	},
];
