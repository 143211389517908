import axios from 'axios';
import cookies from 'js-cookie';

import { TOKEN_COOKIE_NAME } from '@/auth/constants';
import { Country } from '@/types/country';
import { KindFish } from '@/types/kind-fish';
import { Tare } from '@/types/tare';
import { TypeCutting } from '@/types/type-cutting';

export type GetFishSaleFilterResponse = {
	/** Локации складов */
	location?: string[];
	/** Виды рыб */
	kind_fish?: KindFish[];
	/** Виды разделки */
	type_cutting?: TypeCutting[];
	/** Производители */
	product_manufacturer?: string[];
	/** Районы промысла TODO: уточнить после реализации */
	fishing_area?: string[];
	/** Тары */
	tare?: Tare[];
	/** Типы глубокой переработки TODO: уточнить после реализации */
	deep_processing?: string[];
	/** Страны */
	countries?: Country[];
	/** Страны */
	one_piece_weights?: number[];
	/** Список складов */
	warehouses?: {
		id: string;
		title: string;
	}[];
};

/**
 * Получает параметры для фильтров на странице "Купить рыбу"
 */
export async function getFishSaleFilter() {
	const token = cookies.get(TOKEN_COOKIE_NAME);

	const { data } = await axios.get<GetFishSaleFilterResponse>(
		`${process.env.HOST}/api_v1/fish-sale-filter`,
		{
			headers: { Authorization: token ? `Bearer ${token}` : null },
		},
	);

	return data;
}
