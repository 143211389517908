import { FC, Suspense } from 'react';

import Link from 'next/link';

import { DEFAULT_DOCS_LINKS } from '@/constants/common';
import { useTranslate } from '@/hooks/use-translate';
import { DocsLinks } from '@/types/legal';
import Container from '@/UI/Container';

import { en } from './en';
import { TelegramIcon, WhatsAppIcon } from './icons';

import styles from './Footer.module.scss';

type Props = {
	docs: DocsLinks;
};

export const Footer: FC<Props> = ({ docs }) => {
	const { privacyPolicy, userAgreement, termOfUse } = docs ?? DEFAULT_DOCS_LINKS;
	const t = useTranslate(en);

	if (!docs) {
		return <Suspense />;
	}

	return (
		<footer className={styles.footer}>
			<Container className={styles.container}>
				<div className={styles.footer__about}>
					<Link href="/" className={styles.footer__about__logo}>
						<img src="/img/svg/footer-logo.svg" alt="Fishplace" />
					</Link>
					<div className={styles.footer__about__content}>
						<span>&copy;&nbsp;{t('ООО «Мой Фиш Плэйс», 2023-2025')}</span>
						<span>{t('ИНН: 2536338363, ОГРН: 1232500008179')}</span>
					</div>
				</div>
				<div className={styles.footer__navigation}>
					<nav>
						<ul>
							<li>
								<Link href="/depositor-company">{t('Рыбной компании')}</Link>
							</li>
							<li>
								<Link href="/storage">{t('Складу')}</Link>
							</li>
							<li>
								<Link href="/buy-fish">{t('Купить рыбу')}</Link>
							</li>
							<li>
								<Link href="/about-us">{t('О платформе')}</Link>
							</li>
							<li className={styles.sm}>
								<Link href={privacyPolicy ?? ''} target="_blank" rel="noreferrer">
									{t('Конфиденциальность')}
								</Link>
							</li>
							<li className={styles.sm}>
								<Link href="/#tariffs" target="_blank" rel="noreferrer">
									{t('Тарифы')}
								</Link>
							</li>
							<li className={styles.sm}>
								<Link href={userAgreement ?? ''} target="_blank" rel="noreferrer">
									{t('Пользовательское соглашение')}
								</Link>
							</li>
							<li className={styles.sm}>
								<Link href={termOfUse ?? ''} target="_blank" rel="noreferrer">
									{t('Лицензионный договор')}
								</Link>
							</li>
							<li className={styles.sm}>
								<Link href="/help-center" rel="noreferrer">
									{t('Справочный центр')}
								</Link>
							</li>
						</ul>
					</nav>
				</div>
				<div className={styles.footer__contacts}>
					<div className={styles.footer__contacts__item}>
						<div className={styles.footer__contacts__phone}>
							<a href="tel:+7 908 999 80 80">+7 908 999 80 80</a>
						</div>
						<div className={styles.footer__contacts__email}>
							<a href="mailto:hello@fishplace.ru">hello@fishplace.ru</a>
						</div>
					</div>
					<div className={styles.footer__contacts__item}>
						<div className={styles.footer__contacts__socials}>
							<a href="https://t.me/fishplaceru" target="_blank" rel="noreferrer">
								<TelegramIcon />
							</a>
							<a
								href="https://api.whatsapp.com/send?phone=79089998080"
								target="_blank"
								rel="noreferrer"
							>
								<WhatsAppIcon />
							</a>
						</div>
					</div>
				</div>
			</Container>
		</footer>
	);
};
