import { useEffect, useState } from 'react';

import { Button, notification } from 'antd';

export const InstallPwa = () => {
	//BeforeInstallPromptEvent пока что не поддерживается всеми браузерами, поэтому пока any
	const [deferredPrompt, setDeferredPrompt] = useState<any | null>(null);
	const [api, contextHolder] = notification.useNotification();

	useEffect(() => {
		const handler = (e: Event) => {
			e.preventDefault();
			setDeferredPrompt(e as any);
		};

		window.addEventListener('beforeinstallprompt', handler);
		return () => window.removeEventListener('beforeinstallprompt', handler);
	}, []);

	const handleInstall = async () => {
		if (!deferredPrompt) return;

		deferredPrompt.prompt();
		const { outcome } = await deferredPrompt.userChoice;
		if (outcome === 'accepted') {
			console.log('PWA установлено');
		}
		setDeferredPrompt(null);
	};

	useEffect(() => {
		if (deferredPrompt) {
			api.open({
				message: 'Вы можете установить Fishplace в качестве приложения',
				description: <Button onClick={handleInstall}>Установить</Button>,
			});
		}
	}, [deferredPrompt]);

	return <>{contextHolder}</>;
};
