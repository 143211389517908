export const en = {
	'ООО «Мой Фиш Плэйс», 2023-2025': 'Moi Fish Place LLC, 2023-2025',
	'ИНН: 2536338363, ОГРН: 1232500008179': 'TIN: 2536338363, OGRN: 1232500008179',
	'Рыбной компании': 'The fish company',
	Складу: 'Warehouse',
	'Купить рыбу': 'Buy fish',
	'Продать рыбу': 'Sell fish',
	'О платформе': 'About the platform',
	Тарифы: 'Tariffs',
	Конфиденциальность: 'Privacy',
	'Пользовательское соглашение': 'User Agreement',
	'Лицензионный договор': 'License agreement',
	'Справочный центр': 'Help Center',
};
